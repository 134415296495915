@tailwind base;
@tailwind components;
@tailwind utilities;


.wrapper {
  @apply max-w-screen-2xl mx-auto px-20;
}
@media screen and (max-width: 1536px) {
  .wrapper {
    @apply max-w-[1366px];
  }
}
@media screen and (max-width: 1280px) {
  .wrapper {
    @apply max-w-screen-lg;
  }
}
@media screen and (max-width: 1024px) {
  .wrapper {
    @apply max-w-screen-md px-10;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    @apply max-w-screen-sm;
  }
}
@media screen and (max-width: 640px) {
  .wrapper {
    @apply max-w-[475px];
  }
}


.swiper {
  width: "100%";
  height: "100%";
}


.swiper-pagination-bullet {
  background-color: white !important;
  opacity: 1;
  
}

.swiper-pagination-bullet-active {
  background-color: #F37C7A !important
}

